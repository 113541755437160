export function getCapability(action, user) {
	if (user && user.capabilities) {
		var capabilities = JSON.parse(JSON.stringify(user.capabilities));
		let cap = capabilities.filter((item) => item.capability === action);
		if (cap.length > 0) {
			console.log("Capability", cap, action);
			return cap[0].is_able;
		}
	}
	return false;
}

export function isAdmin(user) {
	if (user) {
		return user.type === 1;
	}

	return false;
}

export function seeDashboardMap(user) {
	return isAdmin(user) || user.type === 3;
}
