export const bus =
	"M17.402,0H5.643C2.526,0,0,3.467,0,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644 V6.584C23.044,3.467,20.518,0,17.402,0z M22.057,14.188v11.665l-2.729,0.351v-4.806L22.057,14.188z M20.625,10.773 c-1.016,3.9-2.219,8.51-2.219,8.51H4.638l-2.222-8.51C2.417,10.773,11.3,7.755,20.625,10.773z M3.748,21.713v4.492l-2.73-0.349 V14.502L3.748,21.713z M1.018,37.938V27.579l2.73,0.343v8.196L1.018,37.938z M2.575,40.882l2.218-3.336h13.771l2.219,3.336H2.575z M19.328,35.805v-7.872l2.729-0.355v10.048L19.328,35.805z";
export const truck =
	"M1 22h3v-4h2v4h12v-4h2v4h3v-7h-5V6H6v9H1v7zm2-7h15v5H3v-5zm4-6h8v6H7v-6zm-2 0v6H4v-6h1z";
export const car =
	"M2 15h2l1-4h10l1 4h2c1.1 0 2 .9 2 2v3h-1v4h-2v-4H4v4H2v-4H1v-3c0-1.1.9-2 2-2zm2-2l.5-2h11l.5 2H4zm13 7h2v2h-2v-2zM3 20h2v2H3v-2z";
export const mpv =
	"M2 15h16l1 5h-3v2h-1v-2H5v2H4v-2H1v-5h1zm1 0v3h14v-3H3zM4 16h2v2H4v-2zm12 0h2v2h-2v-2z";
export const motorcycle =
	"M2 16c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm16 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-9-5h2l1 1h6v2h-6l-1 1H9l1-4zM6 14h3v2H6v-2zm9 0h3v2h-3v-2z";
export const genset =
	"M2 12h16v8h-3v3H5v-3H2v-8zm1 1v6h14v-6H3zm2 7h8v1H5v-1zm11 0h2v1h-2v-1zM4 13h1v2H4v-2zm12 0h1v2h-1v-2z";
export const van =
	"M2 15h17v5h-2v2h-1v-2H6v2H5v-2H2v-5zm1 0v3h14v-3H3zm2-5h12v4H5v-4zm1 1v2h10v-2H6zm-2 9h2v2H4v-2zm13 0h2v2h-2v-2z";
export const excavator =
	"M2 14h4v3h3l2 2h6v-3h1v3h1v1h-2v2H4v-2H2v-2l-1-2 1-2zm1 2v2h2v-2H3zm15-2l-3-3h-2l2 3h-2v2h2l-2 3h2l3-3h2l-1-2h-1zM4 19h12v1H4v-1z";

export const optionVehicle = [
	{
		label: "Bus",
		value: "Bus",
		icon: bus,
	},
	{
		label: "Car",
		value: "Car",
		icon: car,
	},
	{
		label: "Motorcycle",
		value: "Motorcycle",
		icon: motorcycle,
	},
	{
		label: "MPV",
		value: "MPV",
		icon: mpv,
	},
	{
		label: "Truck",
		value: "Truck",
		icon: truck,
	},
	{
		label: "Van",
		value: "Van",
		icon: van,
	},
	{
		label: "Genset",
		value: "Genset",
		icon: genset,
	},
	{
		label: "Excavator",
		value: "Excavator",
		icon: excavator,
	},
];
